export const requestStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  error_connection: "error_connection",
  logout: "logout",
};

export const checkoutThemes = {
  1: {
    background: "black",
    loader: "#4b4b4b",
    color: "white",
  },
  2: {
    background: "white",
    loader: "#8e8e8e",
    color: "black",
  },
};
