import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/modules/auth";
import { requestStatus } from "../services/index";
import { getProfile } from "../api/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LandingPage"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegistrationView"),
  },
  {
    path: "/checkout-payment/",
    name: "checkout-payment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/CheckoutPayments"),
  },
  {
    path: "/refund-policy",
    name: "RefundPolicy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/RefundPolicy"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/Policy/TermsAndConditions"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/privacy-policy"),
  },
  {
    path: "/terms-of-purchase",
    name: "terms-of-purchase",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/TermsofPurchase"),
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Policy/MembershipView"),
  },
  {
    path: "/membership-cancellation",
    name: "membership-cancellation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/Policy/MembershipCancellation"
      ),
  },
  {
    path: "/atomic-body-at-home",
    name: "atomic-body-at-home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/AtomicBodyAtHome"),
  },
  {
    path: "/a-diet-you-wont-quit",
    name: "a_diet_you_wont_quit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/ADietYouWontQuit"),
  },
  {
    path: "/breathing-is-life",
    name: "breathing-is-life",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/BreathingIsLife"),
  },
  {
    path: "/healthy-and-toned-back",
    name: "healthy_and_toned_back",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/books/HealthyAndTonedBack"
      ),
  },
  {
    path: "/keto-diet",
    name: "keto-diet",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/books/KetoDietMasterclass"
      ),
  },
  {
    path: "/lose-weight",
    name: "lose-weight",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/books/LoseWeight"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/webcheckout/Checkout"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/webcheckout/Payment"),
  },
  {
    path: "/checkout-verification",
    name: "checkout-verification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/webcheckout/PaymentVerification"
      ),
  },
  {
    path: "/app",
    component: () => import(/* webpackChunkName: "about" */ "../views/AppView"),
    children: [
      {
        path: "/",
        name: "WorkoutBuilder",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/WorkoutBuilder"),
        meta: { requiresAuth: true },
      },
      {
        path: "/guides",
        name: "FitnessGuides",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/FitnessGuides"),
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/AccountSettings"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: "personalInfo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/Settings/PersonalInformation"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "/subscription",
            name: "subscription",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/Settings/SubscriptionsPage"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        meta: { requiresAuth: true },
        path: "/training",
        name: "TrainingWrp",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/TrainingWrp"),
      },
    ],
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

// const GoToNextPage = (next) => {
//   next();
// };

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user && user.token) {
      if (!store.state.currentUser) {
        getProfile().then((response) => {
          if (response.status === requestStatus.success) {
            const profile = response.response.data;
            store.mutations.set(store.state, {
              type: "currentUser",
              items: profile,
            });
            // GoToNextPage(profile, next, to);
            next();
          } else {
            if (
              response.status === requestStatus.logout ||
              response.status === requestStatus.error_connection ||
              response.status === requestStatus.error
            ) {
              window.getApp.$emit(
                "GLOBAL_NOTIFICATION",
                "Oops, an error occurred. Please try again!"
              );
              localStorage.removeItem("user");
              setTimeout(() => {
                window.location.href = "/login";
              }, 1500);
            }
          }
        });
      } else {
        // GoToNextPage(store.state.currentUser, next, to);
        next();
      }
    } else {
      next({ name: "login" });
    }
  } else {
    if (
      (to.path === "/login" ||
        to.path === "/registration" ||
        to.path === "/") &&
      user
    ) {
      next("/app");
    } else {
      next();
    }
  }
});

export default router;
